import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';
import { decode64 } from '../utils/base64';
import { arenas } from '../utils/rooms';
import { useStateValue } from '../Providers/stateProvider';

const ContestDetails = () => {
  const [{ participants: participantState = [], winners: winnerState = [] }] = Object.values(useStateValue());

  const params = useParams();
  const username = decode64(params.id);

  const arenaDetails = arenas.find(a => a.name === username);
  const winnerData = winnerState?.find(w => w.room === username);
  const participantsData = participantState?.find(w => w.room === username);

  const [isCopied, setIsCopied] = useState(false);
  const [participantsToShow, setParticipantsToShow] = useState(10);
  const [winnersToShow, setWinnersToShow] = useState(10);
  const participantLength = 20;
  let winners = [];
  let participants = [];

  if (winnerData) {
    const { data: { batchWinners } } = winnerData;
    winners = batchWinners;
  }

  if (participantsData) {
    const { participants: p } = participantsData;
    participants = p;

  }

  const byDate = (a, b) => {
    return new Date(b.timeOfWinning) - new Date(a.timeOfWinning);
  };

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (

    <>
      <div className="inner-hero-section">
        <div className="bg-shape"><img src="/assets/images/elements/inner-hero-shape.png" alt="hero" /></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ul className="page-list">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/contest">Contests</Link></li>
                <li className="active">Contest No: { arenaDetails.username }</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section className="pb-120 mt-minus-300">
        <div className="container">
          <div className="row justify-content-center">
          </div>
          <div className="row mt-50 pt-3">

            <div className="col-lg-12" style={ { background: '#4939c0', padding: '30px', borderRadius: '10px', marginTop: "30px" } }>
              <div className="contest-details-content">
                <div className="row">
                  <div className="col-lg-9">
                    <span className="subtitle">Enter now for a chance to win</span>
                    <h2 className="contest-name">{ arenaDetails.name } Arena</h2>
                    <div>
                      <div><p>Last Draw: { winners?.length > 0 ? new Date(winners[0].timeOfWinning).toLocaleDateString() : '...' }</p></div>
                      <div className="contest-details-meta">
                        <p>Maximum of <span>{ arenaDetails.poolSize }</span> entries.</p>
                      </div>

                      <div className="contest-details-meta">
                        <p>Amount: <span>{ arenaDetails.amount }</span> XBN</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <p className="text-center pt-3">Scan QR Code</p>
                    <img style={ { width: '100%', maxHeight: '318px' } } src={ arenaDetails.QRCode } alt="2" />
                    <br />
                    <div>
                      <CopyToClipboard text={ arenaDetails.username } onCopy={ onCopyText }>
                        <p style={ { color: "#ffffff", fontWeight: 700, fontSize: "20px" } } className="text-center">Username: <span style={ { color: "#ffffff", fontSize: "20px" } }>{ arenaDetails.username }&nbsp;</span>
                          <i style={ { cursor: 'pointer' } } data-tip="copy" data-for="username" className="las la-copy"></i>
                          <ReactTooltip type="dark" id="username" place="top" effect="solid">
                            <span>{ isCopied ? "copied!" : "Copy username" }</span>
                          </ReactTooltip>
                        </p>
                      </CopyToClipboard>

                    </div>

                  </div>
                </div>


                {/* <div className="contest-description">
                  <div className="content-block">
                    <h3 className="title">Overview</h3>
                    <p>{ arenaDetails.overview }</p>
                  </div>
                </div> */}
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-lg-5 pl-0">
              <div className="past-draw-wrapper">
                <h3 className="title">Participants ({ participants.length })</h3>
                <div className="table-responsive-lg">
                  <table>
                    <thead>
                      <tr>
                        <th>Public Key</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      { participants?.length > 0 ?
                        participants && participants.slice(0, participantsToShow).map((p, i) => (
                          <tr key={ i }>
                            <td><span style={ { fontSize: '13px', color: 'white' } } >{ p?.publicKey?.substring(0, participantLength) + '...' }</span></td>
                            <td><span >{ arenaDetails.amount } XBN</span></td>
                          </tr>
                        )) :
                        <tr>
                          <td colSpan="2" style={ { color: 'white', textAlign: 'center' } }>No Participants yet</td>
                        </tr>
                      }

                    </tbody>
                  </table>
                </div>
                <div className="load-more">
                { participantLength < participants?.length ? 
                  <button type="button" onClick={ () => setParticipantsToShow(participantsToShow + 2) }>Show More <i className="las la-angle-down ml-2"></i></button>
                  :  <button type="button" /> }
                </div> 
              </div>
            </div>

            <div className="col-lg-7 pl-0">
              <div className="past-draw-wrapper">
                <h3 className="title">Latest Winners</h3>
                <div className="table-responsive-lg">
                  <table>
                    <thead>
                      <tr>
                        <th>Draw</th>
                        <th>Batch ID</th>
                        <th>Public Key</th>
                        <th>Amount</th>
                        <th>Position</th>

                      </tr>
                    </thead>
                    <tbody>
                      { winners?.length > 0 ?
                        winners && winners.sort(byDate).slice(0, winnersToShow).map((winner, i) => (
                          <tr key={ i }>
                            <td><span className="fail" style={ { fontSize: '12px', textTransform: 'capitalize' } }>{ moment(winner.timeOfWinning).fromNow() }</span></td>
                            <td><span className="fail">{ winner.batchID }</span></td>
                            <td><span className="fail">{ winner.publicKey.slice(0, 10) }...</span></td>
                            <td><span className="fail">{ winner.amount } XBN</span></td>
                            <td><span className="fail">{ winner.winnerPosition }</span></td>
                          </tr>
                        )) :
                        <tr>
                          <td colSpan="6" style={ { color: 'white', textAlign: 'center' } }>No Winners yet</td>
                        </tr>
                      }

                    </tbody>
                  </table>
                </div>
                <div className="load-more">
                { winnersToShow < winners.length ?
                  <button type="button" onClick={ () => setWinnersToShow(winnersToShow + 10) }>Show More <i className="las la-angle-down ml-2"></i></button>
                  : <button type="button" /> }
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default ContestDetails;
