export const arenas = [
    {
        name: 'Kinshasa',
        poolSize: 5,
        overview: 'Lots of stuff to be said here, okay ingnore now...',
        QRCode: '/assets/images/contest/qr-code-5-5.png',
        amount: '5',
        total: 23.75,
        username: '5/5',
        usernameUrl: 'g5x5'
    },
    {
        name: 'Luanda',
        poolSize: 100,
        overview: 'Lots of stuff to be said here, okay ingnore now...',
        QRCode: '/assets/images/contest/qr-code-5-100.png',
        amount: '5',
        total: 500,
        username: '5/100',
        usernameUrl: 'g5x100'
    },
    {
        name: 'Cairo',
        poolSize: 10,
        overview: 'Lots of stuff to be said here, okay ingnore now...',
        QRCode: '/assets/images/contest/qr-code-100-10.png',
        amount: '100',
        total: 1000,
        username: '100/10',
        usernameUrl: 'g100x10'
    }
];
