import React from 'react';
import { Link } from 'react-router-dom';

import { encode64 } from '../utils/base64';
import { arenas } from '../utils/rooms';

const Contests = () => {
    return (
        <section className="position-relative pt-120 pb-120">
            <div className="bg-el"><img src="/assets/images/elements/contest-bg.png" alt="bg" /></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                        <div className="section-header text-center">
                            <span className="section-sub-title">Try your chance at winning</span>
                            <h2 className="section-title">Current Contests</h2>
                            <p>Participants buy tickets and lots are drawn to determine the winners.</p>
                        </div>
                    </div>
                </div>
                <div className="row wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                    <div className="col-lg-12">
                        <div className="tab-content" id="contestTabContent">
                            <div className="tab-pane fade show active" id="car" role="tabpanel" aria-labelledby="car-tab">
                                <div className="row mb-none-30">
                                    { arenas.map(arena => (
                                        <div key={ arena.username } className="col-xl-4 col-md-6 mb-30">
                                            <div className="contest-card">
                                                <Link to={ `/contest-details/${ encode64(arena.name) }` } className="item-link"></Link>
                                                <div className="contest-card__thumb">
                                                    <img src={ arena.QRCode } alt="2" />
                                                    <a href="#0" className="action-icon"><i className="far fa-heart"></i></a>
                                                    <div className="contest-num">
                                                        <div style={ { color: "#ffffff", textTransform: "capitalize", fontSize: "12px", textAlign: "center", padding: "10px" } }>Pool Size:</div>
                                                        <br />
                                                        <h4 className="number">{ arena.poolSize < 9 ? `0${ arena.poolSize }` : arena.poolSize }</h4>
                                                    </div>
                                                </div>
                                                <div className="contest-card__content">
                                                    <div className="left">
                                                        <h5 className="contest-card__name">{ arena.name } Arena</h5>
                                                    </div>
                                                    <div className="right">
                                                        <span className="contest-card__price">{ arena.amount } XBN</span>
                                                        <p>ticket price</p>
                                                    </div>
                                                </div>
                                                {/* <div className="contest-card__footer">
                                                    <ul className="contest-card__meta">
                                                        <li>
                                                            <i className="las la-money-bill"></i>
                                                            <span>{ arena.total } XBN</span>
                                                        </li>
                                                        <li>
                                                            <i className="las la-user"></i>
                                                            <span>{ arena.username }</span>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                    )) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row mt-30">
                    <div className="col-lg-12">
                        <div className="btn-grp">
                            <Link to='/contest' className="btn-border">browse more</Link>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    );
};

export default Contests;
