import participantsType from '../Providers/reducers/participants/participantsType';
import winnersType from '../Providers/reducers/winners/winnersType';


export const callSockets = (dispatch, arenaDetails, url) => {
    // const url = `wss://${arenaDetails.usernameUrl}.${BASE_URL}/ws`;
    const ws = new WebSocket(url);
    ws.onmessage = (event) => {
        const response = JSON.parse(event.data);
        if (response.stream_type === "participants") {
            dispatch({
                type: participantsType.LOAD_PARTICIPANT_STREAM,
                payload: {
                    participants: response.stream.reverse(),
                    room: arenaDetails.name,
                    username: arenaDetails.username,
                }
            });

        } else if (response.stream_type === "gameBatch") {
            dispatch({
                type: winnersType.NEW_WINNERS_STREAM,
                payload: {
                    room: arenaDetails.name,
                    username: arenaDetails.username,
                    data: response.stream,
                }
            });
        } else if (response.stream_type === "participant") {
            dispatch({
                type: participantsType.NEW_PARTICIPANT,
                payload: {
                    participant: response.stream,
                    room: arenaDetails.name,
                    username: arenaDetails.username,
                }
            });
        } else if (response.stream_type === "gameWinnersHistory") {
            dispatch({
                type: winnersType.LOAD_WINNERS_STREAM,
                payload: {
                    room: arenaDetails.name,
                    username: arenaDetails.username,
                    data: response.stream,
                }
            });
        } else if (response.stream_type === "processedGameBatch") {
            dispatch({
                type: winnersType.LOAD_STATS,
                payload: {
                    room: arenaDetails.name,
                    username: arenaDetails.username,
                    data: response.stream,
                }
            });
        }
        console.log(response, '<<')
    };
    ws.onclose = () => {
        ws.close();
    };

    return () => {
        ws.close();
    };
}