import React from 'react';

const HowToPlay = () => {
  return (
    <section className="position-relative pt-120">
      <div className="play-elements">
        <img src="assets/images/elements/play-el.png" alt="play" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-sm-left text-center">
            <div className="section-header">
              <span className="section-sub-title">Need to know about</span>
              <h2 className="section-title">How To Play</h2>
              <p>Follow these 3 easy steps! </p>
            </div>
          </div>
        </div>
        <div className="row mb-none-30 justify-content-xl-start justify-content-center">
          <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
            <div className="play-card bg_img" data-background="/assets/images/elements/card-bg-1.jpg">
              <div className="play-card__icon">
                <img src="assets/images/icon/play/1.png" alt="play-icon" />
                <span className="play-card__number">01</span>
              </div>
              <div className="play-card__content">
                <h3 className="play-card__title">SELECT ARENA</h3>
                <p>Choose how much you would like to stake to win.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
            <div className="play-card bg_img" data-background="/assets/images/elements/card-bg-2.jpg">
              <div className="play-card__icon">
                <img src="assets/images/icon/play/2.png" alt="play-icon" />
                <span className="play-card__number">02</span>
              </div>
              <div className="play-card__content">
                <h3 className="play-card__title">SEND</h3>
                <p>Send XBN to the designated Mitambo address or scan the QR code using your BantuPay app.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
            <div className="play-card bg_img" data-background="/assets/images/elements/card-bg-3.jpg">
              <div className="play-card__icon">
                <img src="assets/images/icon/play/3.png" alt="play-icon" />
                <span className="play-card__number">03</span>
              </div>
              <div className="play-card__content">
                <h3 className="play-card__title">Win</h3>
                <p>Win the main bag or a consolation prize.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToPlay;
