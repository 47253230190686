import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { callSockets } from '../../utils/sockets';
import { useStateValue } from '../../Providers/stateProvider';
import { arenas } from '../../utils/rooms';

import dotenv from "dotenv";
dotenv.config()

const BASE_URL = process.env.REACT_APP_URL;
const urls = BASE_URL.split(',');

const Navbar = () => {
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    arenas.map((a, i) => callSockets(dispatch, a, urls[i]));
  }, [dispatch]);

  return (
    <header className="header">
      <div className="header__bottom">
        <div className="container">
          <nav className="navbar navbar-expand-xl p-0 align-items-center">
            <Link className="site-logo site-title" to="/">
              <img style={ { width: "300px" } } src="/assets/images/mitambo-logo.png" alt="site-logo" />
              <span className="logo-icon"><i className="flaticon-fire"></i></span></Link>
            <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="menu-toggle"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav main-menu ml-auto">
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/contest'>Contest</Link></li>
                <li><Link to='/winner'>Winners</Link></li>
              </ul>
              <div className="nav-right">
                <Link to="/contest" className="cmn-btn style--three btn--sm"><img src="/assets/images/icon/btn/tag.png" alt="icon" className="mr-2" /> Buy Tickets</Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
